var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.verticalCard)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(!_vm.$b.d && _vm.onLinkBlurMobile),expression:"!$b.d && onLinkBlurMobile"}],class:[
		'pb-2 product-card-design4-with-timer-vertical-wrapper',
		{
			h100: !_vm.timerProduct,
			'product-card-design4-with-timer-vertical-wrapper-forBtn': _vm.btnHover,
			'product-card--align-right': _vm.alignment == 'right',
			'product-card--align-center': _vm.alignment == 'center',
		} ]},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{class:[
				'd-flex flex-column base rounded-md overflow-hidden product-card',
				_vm.$bem('product-card-design4-with-timer-vertical'),
				{
					'product-card-design4-with-timer-vertical__positionRight': _vm.position == 'right',
					'product-card-design4-with-timer-vertical__positionLeft': _vm.position == 'left',
					'card-border': _vm.cardBorder,
					h100: _vm.timerProduct && _vm.limitedTimeOffer,
				} ],attrs:{"hover":!_vm.outOfStock ? true : false,"flat":"","ripple":false},on:{"click":_vm.cardClick}},[_c('ProductCard-AttrsTags',{staticClass:"pt-3 pl-4"}),_c('div',{class:[_vm.$bem('__image-cont'), { 'no-stock': _vm.outOfStock }]},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"src":_vm.mainImage,"width":"100%","aspect-ratio":"1"}}),(_vm.secondImage && _vm.hover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), _vm.hover ? _vm.$bem('__second-image--show') : ''],attrs:{"src":_vm.secondImage,"width":"100%"}})],1):_vm._e()],1),_c('hook',{attrs:{"zone":"attrs&financingTags"}},[_c('ProductCard-FinancingTag')],1),_c('hook',{attrs:{"zone":"event-icon"}},[_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product}})],1)],1),_c('div',{class:[
					_vm.$bem('__data-cont'),
					'd-flex flex-column mx-3',
					{ 'no-stock': _vm.outOfStock, 'pb-4': !_vm.timerProduct, 'h100 justify-space-between': _vm.hasBtn } ]},[_c('hook',{attrs:{"zone":"brand"}},[_c('ProductCardText',{attrs:{"textClass":[
							_vm.$bem('__brand'),
							'line-clamp-1 px-1',
							_vm.alignment == 'left'
								? 'text-start'
								: _vm.alignment == 'center'
								? 'text-center'
								: 'text-end',
							_vm.timerProduct ? 'font-0' : 'font-1' ],"conditional":_vm.brand,"text":_vm.brand}})],1),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var navigate = ref.navigate;
						var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){!_vm.$b.d ? _vm.onLinkClickMobile(navigate, $event) : navigate($event)}}},[_c('hook',{attrs:{"zone":"name"}},[_c('ProductCardText',{attrs:{"textClass":[
									_vm.$bem('__name'),
									_vm.timerProduct ? 'font-2' : 'font-3',
									'line-clamp-2 px-1 header mb-2',
									_vm.alignment == 'left'
										? 'text-start'
										: _vm.alignment == 'center'
										? 'text-center'
										: 'text-end' ],"text":_vm.product.name}})],1)],1)]}}],null,false,4224509204)}),_c('ProductCard-AttrSelect'),_c('hook',{attrs:{"zone":"textTags"}},[_c('ProductCardText',{attrs:{"textClass":[
							_vm.$bem('__text-tags'),
							_vm.timerProduct ? 'font-0' : 'font-1',
							'line-clamp-1 px-1 primary rounded mb-2',
							{
								'alignment-center': _vm.alignment == 'center',
								'alignment-right': _vm.alignment == 'right',
							} ],"conditional":_vm.hasTextTag,"text":_vm.textTag}})],1),_c('ProductCard-Tags',{class:_vm.timerProduct ? 'font-0' : 'font-1'}),_c('ProductCardPrice',{attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":[
						_vm.$bem('__prev-price'),
						'd-flex align-center pb-2 line-clamp-1',
						{ 'justify-center': _vm.alignment == 'center', 'justify-end': _vm.alignment == 'right' } ],"prevPriceClass":[_vm.timerProduct ? 'font-2' : 'font-3', 'grey--text mr-2'],"priceClass":[
						_vm.$bem('__price'),
						'price line-clamp-1',
						_vm.timerProduct ? 'font-6' : 'font-7',
						{
							'text-center': _vm.alignment == 'center',
							'text-end': _vm.alignment == 'right',
						} ],"discountClass":[
						_vm.$bem('__discount'),
						_vm.timerProduct ? 'font-2' : 'font-3',
						'd-inline-block discount' ],"prevPriceGroup":""}}),_c('ProductCardRating',{attrs:{"product":_vm.product}}),(_vm.hasBtn)?_c('div',{class:[
						'pa-3',
						{
							btnHoverHorizontal: _vm.btnHover,
							'btnHoverHorizontal--show': _vm.btnHover && _vm.hover,
							z1:
								(_vm.product.hasUniqueVariant || _vm.showVariantSelector) &&
								!_vm.product.info.customizationKey,
						} ]},[_c('hook',{attrs:{"zone":"btn"}},[(_vm.btnType == 'btn' || _vm.btnType == 'btnHover')?_c('v-btn',{staticClass:"cta w100 rounded-md",attrs:{"disabled":_vm.buttonText == 'Sin stock',"loading":_vm.btnIsLoading},on:{"click":_vm.btnClicked}},[_vm._v(" "+_vm._s(_vm._f("lang")(_vm.buttonText))+" ")]):_c('ProductCard-QtyInput')],1)],1):_vm._e(),(_vm.timerProduct)?_c('hook',{attrs:{"zone":"offerTimerProduct"}},[(_vm.limitedTimeOffer)?_c('div',{class:[_vm.$bem('__offerTimerProduct'), 'base pt-1 mt-3']},[_c('LimitedTimeOfferCountdown',{staticClass:"mt-3",attrs:{"limitedTimeOffer":_vm.limitedTimeOffer,"timerCard":""}}),_c('div',{staticClass:"mt-2 pb-2 text-center"},[_c('p',{class:[
									'text-uppercase line-clamp-3 mb-0 py-1 px-2 rounded font-0',
									_vm.$bem('__timer-date') ]},[_vm._v(" "+_vm._s(_vm.limitedTimeOfferDate)+" ")])])],1):_vm._e()]):_vm._e()],1)],1)],1)],1):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onLinkBlurMobile),expression:"onLinkBlurMobile"}],class:{ 'product-card-design4-with-timer-horizontal-wrapper-forBtn': _vm.btnHover && _vm.showBtnHoverMobile }},[_c('v-card',{class:[
			'd-flex overflow-hidden base product-card pt-2 flex-column',
			_vm.$bem('product-card-design4-with-timer-horizontal'),
			{
				'product-card-design4-with-timer-horizontal__noTimer': !_vm.timerProduct && _vm.$b.t,
				cardBorder: _vm.cardBorder,
			} ],attrs:{"flat":"","ripple":false},on:{"click":_vm.cardClick}},[_c('div',{staticClass:"d-flex overflow-hidden py-2"},[_c('div',{class:[
					_vm.$bem('__image-cont', 'col col-4 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': _vm.outOfStock } ]},[_c('hook',{attrs:{"zone":"imageHorizontal"}},[_c('Media',{class:_vm.$bem('__main-image', 'px-1'),attrs:{"src":_vm.mainImage,"width":_vm.$b.m ? '100%' : '200px',"maxHeight":_vm.$b.t ? '200px' : '',"imgClass":"product-card-design4-with-timer-horizontal__image--smallWidth"}})],1)],1),_c('div',{class:[
					'col col-8 py-0 d-flex flex-column justify-center text-start',
					_vm.$bem('__data-cont'),
					{ 'no-stock': _vm.outOfStock },
					_vm.$b.t ? 'pl-3' : 'pl-1' ]},[_c('hook',{attrs:{"zone":"brand"}},[(_vm.financingTagHtml)?_c('div',{class:[
							_vm.$bem('__brand'),
							'line-clamp-1',
							_vm.timerProduct ? 'font-0' : 'font-1',
							{ 'mt-5': _vm.financingTagHtml } ],domProps:{"innerHTML":_vm._s(_vm.financingTagHtml)}}):_vm._e()]),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var navigate = ref.navigate;
						var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){return _vm.onLinkClickMobile(navigate, $event)}}},[_c('hook',{attrs:{"zone":"nameHorizontal"}},[_c('div',{class:[
									_vm.$bem('__name'),
									'line-clamp-2 header',
									_vm.$b.m ? 'font-3' : 'font-4',
									{ 'mt-5': _vm.financingTagHtml && !_vm.brand } ]},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])])],1)]}}])}),_c('ProductCard-AttrSelect'),_c('hook',{attrs:{"zone":"textTagsHorizontal"}},[_c('ProductCardText',{attrs:{"textClass":[_vm.$bem('__text-tags'), 'font-0 line-clamp-1 px-1 primary rounded'],"conditional":_vm.hasTextTag,"text":_vm.textTag}})],1),_c('ProductCardPrice',{attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":[_vm.$bem('__prev-price'), 'd-flex align-center pb-2 line-clamp-1'],"prevPriceClass":[_vm.$b.m ? 'font-1' : 'font-2', 'grey--text'],"priceClass":[_vm.$bem('__price'), 'price line-clamp-1', _vm.$b.m ? 'font-5' : 'font-6'],"discountClass":[
						_vm.$bem('__discount'),
						'd-inline-block discount ml-2',
						_vm.$b.m ? 'font-1' : 'font-2' ],"prevPriceGroup":"","horizontalCard":""}})],1)]),_c('ProductCardRating',{attrs:{"product":_vm.product}}),(_vm.hasBtn)?_c('div',{class:[
				'py-1 px-3',
				{
					btnHoverHorizontal: _vm.btnHover,
					'btnHoverHorizontal--show': _vm.btnHover && _vm.showBtnHoverMobile,
					z1: (_vm.product.hasUniqueVariant || _vm.showVariantSelector) && !_vm.product.info.customizationKey,
					'mb-3': !_vm.timerProduct,
				} ]},[_c('hook',{attrs:{"zone":"btn"}},[_c('v-btn',{staticClass:"cta w100 rounded-md",attrs:{"disabled":_vm.buttonText == 'Sin stock',"loading":_vm.btnIsLoading},on:{"click":_vm.btnClicked}},[_vm._v(" "+_vm._s(_vm._f("lang")(_vm.buttonText))+" ")])],1)],1):_vm._e(),(_vm.timerProduct)?_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"offerHorizontalTimerProduct"}},[(_vm.limitedTimeOffer)?_c('div',{class:[_vm.$bem('__offerTimerProduct'), 'base pt-1', { 'mt-4': _vm.$b.t }]},[_c('LimitedTimeOfferCountdown',{staticClass:"mt-2",attrs:{"limitedTimeOffer":_vm.limitedTimeOffer,"timerCard":""}}),_c('div',{staticClass:"mt-2 pb-2 text-center"},[_c('p',{staticClass:"text-uppercase line-clamp-3 mb-0 pt-1 pb-2 px-2 rounded font-0",class:_vm.$bem('__timer-date')},[_vm._v(" "+_vm._s(_vm.limitedTimeOfferDate)+" ")])])],1):_vm._e()]):_vm._e(),_c('hook',{attrs:{"zone":"attrs&financingTagsHorizontal"}},[(_vm.financingTagHtml)?_c('div',{class:[_vm.$bem('__financing'), 'font-0 line-clamp-1 px-1 primary rounded'],domProps:{"innerHTML":_vm._s(_vm.financingTagHtml)}}):_vm._e()]),_c('hook',{attrs:{"zone":"event-iconHorizontal"}},[_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
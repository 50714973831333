<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin, hooksMixin('ProductCard')],
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		:class="[
			verticalWrapperClasses,
			{
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
		v-if="verticalCard"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				@click="cardClick"
				:ripple="false"
				elevation="2"
				:class="[
					$bem('product-card-design2-vertical'),
					{ 'card-border': cardBorder },
					verticalCardClasses,
				]"
				:style="{ height: btnHover && hover ? hoverForBtn : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-3',
						{ 'no-stock': outOfStock, 'h100 justify-space-between': btn },
					]"
				>
					<router-link class="card-anchor header" :to="productRoute">
						<hook zone="name">
							<ProductCardText
								:textClass="[
									$bem('__name'),
									'line-clamp-2 font-2 px-1 header mt-2',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
								]"
								:text="product.name"
							/>
						</hook>
					</router-link>

					<ProductCard-AttrSelect />

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[
									$bem('__brand'),
									'font-0 line-clamp-1 px-1',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
								]"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>

					<hook zone="attrs&financingTags">
						<ProductCard-FinancingTag />
					</hook>

					<hook zone="textTags">
						<ProductCard-TextTag />
					</hook>

					<ProductCard-Tags class="font-0" />

					<v-spacer />

					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							'd-flex align-center line-clamp-1 px-1',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						:prevPriceClass="[$bem('__prev-price'), 'grey--text mr-2 font-0']"
						:priceClass="[$bem('__price'), 'price font-4 line-clamp-1']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						:elementOrder="{ prevPrice: 1, price: 2 }"
					/>

					<ProductCardRating :product="product" />

					<ProductCard-AttrsTags />

					<div
						class="pa-3"
						v-if="hasBtn"
						:class="{
							btnHoverVertical: btnHover,
							'btnHoverVertical--show': btnHover && hover,
							z1: (product.hasUniqueVariant || showVariantSelector) && !product.info.customizationKey,
						}"
					>
						<hook zone="btn">
							<v-btn
								v-if="btnType == 'btn' || btnType == 'btnHover'"
								class="cta w100 rounded-md"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
							<ProductCard-QtyInput v-else />
						</hook>
					</div>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE SHOP -->
	<div class="w100" v-else v-click-outside="onLinkBlurMobile">
		<v-card
			@click="cardClick"
			:ripple="false"
			:class="[
				$bem('product-card-design2-horizontal'),
				{ 'card-border': cardBorder },
				horizontalCardClasses,
			]"
		>
			<div
				:class="[
					$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': outOfStock },
				]"
			>
				<hook zone="imageHorizontal">
					<Media
						:class="$bem('__main-image', 'px-1')"
						:src="mainImage"
						width="100%"
						imgClass="product-card-design2-horizontal__image--smallWidth"
					/>
				</hook>

				<hook zone="offerHorizontal">
					<LimitedTimeOffer
						:product="product"
						textAlign="start"
						class="mx-2 rounded line-clamp-1"
						style="width: inherit; padding: 0 4px !important"
						v-if="hasLimitedTimeOffer"
					/>
				</hook>
			</div>

			<div
				class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
				:class="[$bem('__data-cont'), { 'no-stock': outOfStock }]"
			>
				<router-link :to="productRoute" custom v-slot="{ navigate, href }">
					<a class="card-anchor header" @click="onLinkClickMobile(navigate, $event)" :href="href">
						<hook zone="nameHorizontal">
							<ProductCardText
								:textClass="[$bem('__name'), 'line-clamp-2 font-3 header', { 'mt-3': hasBtn }]"
								:text="product.name"
							/>
						</hook>
					</a>
				</router-link>

				<ProductCard-AttrSelect />

				<hook zone="brand">
					<div :class="$bem('__brand-wrapper')">
						<ProductCardText
							:textClass="[$bem('__brand'), 'font-0 line-clamp-1']"
							:conditional="!!brand"
							:text="brand"
						/>
					</div>
				</hook>

				<hook zone="attrs&financingTagsHorizontal">
					<!-- <div :class="$bem('__financing-wrapper')">
						<div
							:class="[
								$bem('__financing'),
								'font-0 line-clamp-1 px-1 primary rounded',
								{
									'mb-1': !hasTextTag && discountPct,
								},
							]"
							v-if="financingTagHtml"
							v-html="financingTagHtml"
						/>
					</div> -->
					<ProductCard-FinancingTag />
				</hook>

				<hook zone="textTagsHorizontal">
					<ProductCard-TextTag />
				</hook>

				<ProductCard-Tags class="font-0" />

				<ProductCardPrice
					:product="product"
					:currency="currency"
					:priceAmount="price"
					:prevPriceAmount="prevPrice"
					:discountPct="discountPct"
					:containerClass="['d-flex align-center line-clamp-1', { 'pb-2': !hasBtn }]"
					:prevPriceClass="['grey--text font-1 mr-1', $bem('__prev-price')]"
					:priceClass="[$bem('__price'), 'price font-6 line-clamp-1']"
					:discountClass="[$bem('__discount'), 'discount pa-2']"
					horizontalCard
					:elementOrder="{ prevPrice: 1, price: 2 }"
				/>
				<ProductCardRating :product="product" />

				<ProductCard-AttrsTags :vertical-card="false" class="py-1" />

				<div
					v-if="hasBtn"
					:class="{
						btnHoverHorizontal: btnHover,
						'btnHoverHorizontal--show': btnHover && showBtnHoverMobile,
						z1: (product.hasUniqueVariant || showVariantSelector) && !product.info.customizationKey,
					}"
				>
					<hook zone="btn">
						<v-btn
							v-if="btnType == 'btn' || btnType == 'btnHover'"
							class="cta w100 rounded-md"
							:disabled="buttonText == 'Sin stock'"
							:loading="btnIsLoading"
							@click="btnClicked"
						>
							{{ buttonText | lang }}
						</v-btn>
						<ProductCard-QtyInput v-else />
					</hook>
				</div>

				<hook zone="event-iconHorizontal">
					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</hook>
			</div>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE SHOP -->
</template>

<style lang="scss" scoped>
.card-border {
	border: 1px solid #b2b2b2;
}
.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design2-vertical-wrapper {
	min-height: 380px;
	width: 250px;

	&-forBtn {
		padding-bottom: 50px;
	}
}

.product-card-design2-vertical {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: -4px;
	}

	&__event-icon {
		position: absolute;
		bottom: -15px;
		left: 8px;
		z-index: 1;
		width: 40px;
	}

	&__name {
		display: -webkit-box;
		margin-top: 15px;
		min-height: 47.59px;
	}

	& ::v-deep .product-card__financing-wrapper {
		min-height: 18.19px;
	}

	&__brand-wrapper {
		min-height: 18.19px;
	}

	&__price-container {
		min-width: 78.8px;
	}

	&__brand {
		display: -webkit-box;
	}

	& ::v-deep .product-card__attrs-tags {
		min-height: 30.2px;
	}

	& ::v-deep .product-card__qty-input {
		min-height: 42px;
	}
}

::v-deep .product-card-design2-vertical {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.alignment-right {
		float: right;
	}

	&__prev-price {
		column-gap: 8px;
	}
	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		border-bottom-left-radius: 4px;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design2-horizontal {
	min-height: 200px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__event-icon {
		position: absolute;
		top: 4px;
		right: 6px;
		z-index: 1;
		width: 25px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	// &__name {
	// min-height: 53.19px;
	// }
}

::v-deep div.product-card-design2-horizontal {
	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}

	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}
}
</style>


<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	mixins: [productCardMixin, hooksMixin('ProductCard')],
	lang: 'shop',
	props: {
		position: String,
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		v-if="verticalCard"
		:class="[
			'pb-2 product-card--featured product-card-design1-with-timer-vertical-wrapper',
			{
				h100: !timerProduct,
				'product-card-design1-with-timer-vertical-wrapper-forBtn': btnHover,
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
		v-click-outside="!$b.d && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				flat
				@click="cardClick"
				:ripple="false"
				:class="[
					$bem('product-card-design1-with-timer-vertical'),
					{
						'product-card-design1-with-timer-vertical__positionRight': position == 'right',
						'product-card-design1-with-timer-vertical__positionLeft': position == 'left',
						'card-border': cardBorder,
						h100: timerProduct && limitedTimeOffer,
					},
				]"
				class="d-flex flex-column base rounded-md overflow-hidden product-card"
			>
				<ProductCard-AttrsTags class="pt-3 pl-4" />

				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-3',
						{ 'no-stock': outOfStock, 'pb-4': !timerProduct, 'h100 justify-space-between': hasBtn },
					]"
				>
					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="!$b.d ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<hook zone="name">
								<ProductCardText
									:textClass="[
										$bem('__name'),
										timerProduct ? 'font-2' : 'font-3',
										'line-clamp-2 px-1 header',
										alignment == 'left'
											? 'text-start'
											: alignment == 'center'
											? 'text-center my-2'
											: 'text-end my-2',
									]"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<ProductCard-AttrSelect />

					<hook zone="attrs&financingTags">
						<ProductCard-FinancingTag />
					</hook>

					<hook zone="textTags">
						<div
							:class="[
								$bem('__text-tags'),
								timerProduct ? 'font-0' : 'font-1',
								'line-clamp-1 px-1 primary rounded',
								{
									'alignment-center': alignment == 'center',
									'alignment-right mt-1': alignment == 'right',
								},
							]"
							v-if="hasTextTag"
						>
							{{ textTag }}
						</div>
					</hook>

					<ProductCard-Tags :class="timerProduct ? 'font-0' : 'font-1'" />

					<v-spacer v-if="!prevPrice" />

					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							$bem('__prev-price'),
							'd-flex align-center pb-2 line-clamp-1',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						:prevPriceClass="[timerProduct ? 'font-2' : 'font-3', 'grey--text']"
						:priceClass="[
							$bem('__price'),
							'price line-clamp-1 mt-2',
							timerProduct ? 'font-6' : 'font-7',
							{
								'text-center': alignment == 'center',
								'text-end': alignment == 'right',
							},
						]"
						:discountClass="[
							$bem('__discount'),
							timerProduct ? 'font-2' : 'font-3',
							'd-inline-block discount',
						]"
						prevPriceGroup
					/>

					<ProductCardRating :product="product" />

					<div
						v-if="hasBtn"
						:class="[
							'pa-3',
							{
								btnHoverHorizontal: btnHover,
								'btnHoverHorizontal--show': btnHover && hover,
								z1:
									(product.hasUniqueVariant || showVariantSelector) &&
									!product.info.customizationKey,
							},
						]"
					>
						<hook zone="btn">
							<v-btn
								v-if="btnType == 'btn' || btnType == 'btnHover'"
								class="cta w100 rounded-md"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
							<ProductCard-QtyInput v-else />
						</hook>
					</div>

					<hook zone="offerTimerProduct" v-if="timerProduct">
						<div :class="[$bem('__offerTimerProduct'), 'base pt-1 mt-3']" v-if="limitedTimeOffer">
							<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard class="mt-3" />
							<div class="mt-2 pb-2 text-center">
								<p
									class="text-uppercase line-clamp-3 mb-0 py-1 px-2 rounded font-0"
									:class="$bem('__timer-date')"
								>
									{{ limitedTimeOfferDate }}
								</p>
							</div>
						</div>
					</hook>

					<hook zone="wishlist-toggler" :class="{ 'no-stock': outOfStock }">
						<ProductWishlistToggler
							:productId="product.id"
							:class="$bem('__wishlist-toggler')"
							style="z-index: 1"
						/>
					</hook>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE -->
	<div
		v-else
		:class="{ 'product-card-design1-with-timer-horizontal-wrapper-forBtn': btnHover && showBtnHoverMobile }"
		v-click-outside="onLinkBlurMobile"
	>
		<v-card
			flat
			@click="cardClick"
			:ripple="false"
			:class="[
				'd-flex overflow-hidden base product-card pt-2 flex-column',
				$bem('product-card-design1-with-timer-horizontal'),
				{
					'product-card-design1-with-timer-horizontal__noTimer': !timerProduct && $b.t,
					'card-border': cardBorder,
				},
			]"
		>
			<div class="d-flex overflow-hidden py-2">
				<div
					:class="[
						$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
						{ 'no-stock': outOfStock },
					]"
				>
					<hook zone="imageHorizontal">
						<Media
							:class="$bem('__main-image', 'px-1')"
							:src="mainImage"
							:width="$b.m ? '100%' : '200px'"
							:maxHeight="$b.t ? '200px' : ''"
							imgClass="product-card-design1-with-timer-horizontal__image--smallWidth"
						/>
					</hook>
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'col col-7 py-0 d-flex flex-column justify-center text-start',
						{ 'no-stock': outOfStock },
						$b.t ? 'pl-3' : 'pl-1',
					]"
				>
					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a class="card-anchor header" @click="onLinkClickMobile(navigate, $event)" :href="href">
							<hook zone="nameHorizontal">
								<ProductCardText
									:textClass="[$bem('__name'), 'line-clamp-2 header', $b.m ? 'font-3' : 'font-4']"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<ProductCard-AttrSelect />

					<hook zone="attrs&financingTagsHorizontal">
						<div v-if="financingTagHtml && financingTagHtml.length">
							<div
								v-for="(html, n) in financingTagHtml"
								:key="n"
								:class="[
									$bem('__financing'),
									'font-0 line-clamp-1 px-1 primary rounded',
									{
										'mb-1': !hasTextTag && discountPct,
									},
								]"
								v-html="html"
							></div>
						</div>
					</hook>

					<hook zone="textTagsHorizontal">
						<ProductCardText
							:textClass="[$bem('__text-tags'), 'font-0 line-clamp-1 px-1 primary rounded']"
							:conditional="hasTextTag"
							:text="textTag"
						/>
					</hook>

					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[$bem('__prev-price'), 'd-flex align-center pb-2 line-clamp-1']"
						:prevPriceClass="[$b.m ? 'font-1' : 'font-2', 'grey--text']"
						:priceClass="[$bem('__price'), 'price line-clamp-1', $b.m ? 'font-5' : 'font-6']"
						:discountClass="[
							$bem('__discount'),
							'd-inline-block discount ml-1',
							$b.m ? 'font-1' : 'font-2',
						]"
						prevPriceGroup
						horizontalCard
					/>
				</div>
			</div>

			<ProductCardRating :product="product" />

			<div
				v-if="hasBtn"
				:class="[
					'pa-3',
					{
						btnHoverHorizontal: btnHover,
						'btnHoverHorizontal--show': btnHover && showBtnHoverMobile,
						z1: (product.hasUniqueVariant || showVariantSelector) && !product.info.customizationKey,
						'mb-3': !timerProduct,
					},
				]"
			>
				<hook zone="btn">
					<v-btn
						class="cta w100 rounded-md"
						:disabled="buttonText == 'Sin stock'"
						:loading="btnIsLoading"
						@click="btnClicked"
					>
						{{ buttonText | lang }}
					</v-btn>
				</hook>
			</div>

			<hook zone="offerHorizontalTimerProduct" :class="{ 'no-stock': outOfStock }" v-if="timerProduct">
				<div
					:class="[$bem('__offerTimerProduct'), 'base pt-1', { 'mt-4': $b.t }]"
					v-if="limitedTimeOffer"
				>
					<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard class="mt-2" />
					<div class="mt-2">
						<p
							:class="[
								$bem('__timer-date'),
								'text-uppercase line-clamp-3 mb-0 pt-1 pb-2 px-2 rounded font-0',
							]"
						>
							{{ limitedTimeOfferDate }}
						</p>
					</div>
				</div>
			</hook>

			<hook zone="wishlist-togglerHorizontal">
				<ProductWishlistToggler
					:productId="product.id"
					:class="[$bem('__wishlist-toggler'), { 'no-stock': outOfStock }]"
					style="z-index: 1"
					:height="30"
					:width="30"
				/>
			</hook>

			<hook zone="event-iconHorizontal">
				<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
			</hook>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE -->
</template>

<style lang="scss" scoped>
.card-border {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design1-with-timer-vertical {
	position: relative;
	cursor: pointer;
	justify-content: space-between;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: -15px;
		left: 8px;
		z-index: 1;
		width: 40px;
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__name {
		display: -webkit-box;
		margin-top: 15px;
	}

	&__positionRight {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&__positionLeft {
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}
}

::v-deep .product-card-design1-with-timer-vertical {
	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}

	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.alignment-right {
		float: right;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design1-with-timer-horizontal {
	&__image-cont {
		position: relative;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__price,
	&__prev-price,
	&__name,
	&__timer-date {
		display: -webkit-box;
	}

	&__event-icon {
		position: absolute;
		top: 4px;
		right: 4px;
		width: 25px;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 4px;
		left: 6px;
		z-index: 1;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}
}

::v-deep .product-card-design1-with-timer-horizontal {
	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__price,
	&__prev-price {
		display: -webkit-bok;
	}
}
</style>

